import { Button, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import LoginAlert from "../../components/LoginAlert";
import { doc, getFirestore, setDoc } from "firebase/firestore"
import MetaValues from "../../components/MetaValues";

export default function Signin() {
  const [formData, setFormData] = React.useState({ email: "", password: "", passwordRp: "", name: "", phone: "", });
  const [helperTexts, setHelperTexts] = React.useState({ name: "", phone: "", email: "", password: "", passwordRp: "", });
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  function handleChange(event) {
    const { id, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [id]: value,
    }))
  }

  function handleSubmit() {
    //Check validity
    let ht = { name: "", phone: "", email: "", password: "", passwordRp: "", };
    let valid = true;

    if (formData.password !== formData.passwordRp) {
      ht.passwordRp = "Die Passwörter unterscheiden sich";
      valid = false;
    }
    if (formData.password.lenth < 6) {
      ht.password = "Das Passwort muss mindestens 6 Zeichen haben";
      valid = false;
    }
    if (formData.name === "") {
      ht.name = "Bitte tragen Sie Ihren Namen ein";
      valid = false;
    }
    if (formData.email === "") {
      ht.email = "Bitte tragen Sie Ihre E-Mail Adresse ein";
      valid = false;
    }
    if (formData.phone === "") {
      ht.phone = "Bitte tragen Sie Ihre Telefonnummer ein, sodass wir sie im Problemfall kontaktieren können";
      valid = false;
    }
    if (formData.password === "") {
      ht.password = "Bitte tragen Sie ein Passwort ein";
      valid = false;
    }

    setHelperTexts(ht);
    if (!valid)
      return;

    createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then(userCredentials => {
        //Signed up
        /* sendEmailVerification(userCredentials.user); */
        setDoc(doc(db, "users", userCredentials.user.uid), {
          email: formData.email,
          name: formData.name,
          phone: formData.phone
        })
        navigate("/dashboard", { replace: true });
      })
      .catch(error => {
        //Error
        console.log(error.code + " | " + error.message);
        switch (error.code) {
          case "auth/email-already-in-use":
            setAlertText("Diese E-Mail Adresse ist bereits registriert");
            break;
          case "auth/invalid-email":
            setAlertText("Diese E-Mail Adresse ist ungültig");
            break;
          case "auth/operation-not-allowed":
            setAlertText("Unbekannter Fehler. Melden Sie sich bei Fragen an den Support. Fehler: auth/operation-not-allowed");
            break;
          case "auth/weak-password":
            setAlertText("Das Passwort ist zu schwach");
            break;
          default:
            break;
        }
        setAlertOpen(true);
      });
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", flexGrow: 1 }}>
      <MetaValues title="Registrieren" />
      <Paper elevation={6} sx={{ padding: 5, m: 2, minWidth: 350, maxWidth: 400 }} >
        <Typography variant="h4" component="h1" mb={3} textAlign="center">Registrierung</Typography>

        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField id="name" label="Name" type="text" autoComplete="name" value={formData.name} margin="dense" onChange={handleChange} error={helperTexts.name !== ""} helperText={helperTexts.name}></TextField>
            <TextField id="phone" label="Telefonnummer" type="tel" autoComplete="tel" value={formData.phone} margin="dense" onChange={handleChange} error={helperTexts.phone !== ""} helperText={helperTexts.phone}></TextField>
            <TextField id="email" label="Email Adresse" type="email" autoComplete="email" value={formData.email} margin="dense" onChange={handleChange} error={helperTexts.email !== ""} helperText={helperTexts.email}></TextField>
            <TextField id="password" label="Passwort" type="password" autoComplete="new-password" value={formData.password} margin="dense" onChange={handleChange} error={helperTexts.password !== ""} helperText={helperTexts.password}></TextField>
            <TextField id="passwordRp" label="Passwort wiederholen" type="password" autoComplete="new-password" value={formData.passwordRp} margin="dense" onChange={handleChange} error={helperTexts.passwordRp !== ""} helperText={helperTexts.passwordRp}></TextField>
            <Button variant="contained" type="submit" sx={{ mt: 2, mb: 2 }}>Registrieren</Button>
            <LoginAlert open={alertOpen} text={alertText} />
            <Button variant="text" component={RouterLink} to="/auth" sx={{ mt: 2 }}>Ich habe bereits ein Konto</Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}