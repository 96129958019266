import { Box } from "@mui/material";

export default function FooterFlag(props) {
  return (
    <Box
      component="img"
      src={props.img}
      alt={props.alt}
      sx={{ objectFit: "cover", width: "100%", height: "100%", maxWidth: props.small ? 40 : 70, borderRadius: 1 }}
    />
  );
}