import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AboutPropertyElement from "../../components/AboutPropertyElement";
import Welzow1 from "../../images/Welzow1.webp";
import Stellplatz1 from "../../images/Stellplatz (1).webp";
import Stellplatz2 from "../../images/Stellplatz (2).webp";
import Stellplatz3 from "../../images/Stellplatz (3).webp";
import Stellplatz4 from "../../images/Stellplatz (4).webp";
import Stellplatz5 from "../../images/Stellplatz (5).webp";
import Stellplatz6 from "../../images/Stellplatz (6).webp";
import Stellplatz7 from "../../images/Stellplatz (7).webp";
import Stellplatz8 from "../../images/Stellplatz (8).webp";
import Stellplatz9 from "../../images/Stellplatz (9).webp";
import Stellplatz10 from "../../images/Stellplatz (10).webp";
import Stellplatz11 from "../../images/Stellplatz (11).webp";
import Stellplatz12 from "../../images/Stellplatz (12).webp";
import Stellplatz13 from "../../images/Stellplatz (13).webp";
import Stellplatz14 from "../../images/Stellplatz (14).webp";
import Stellplatz15 from "../../images/Stellplatz (15).webp";
import Stellplatz16 from "../../images/Stellplatz (16).webp";
import InfoElement from "../../components/landing/InfoElement";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterIcon from '@mui/icons-material/Water';
import DrainageIcon from '@mui/icons-material/Delete';
import MetaValues from "../../components/MetaValues";

export default function TinyhofWelzowStellplaetze() {
  function handleClick(areaId) {
    navigate("/stellplaetze/buchen", { state: { areaId } });
  }

  const navigate = useNavigate();

  const imgs = [Stellplatz1, Stellplatz2, Stellplatz3, Stellplatz4, Stellplatz8, Stellplatz9, Stellplatz10, Stellplatz11, Stellplatz12, Stellplatz13, Stellplatz14, Stellplatz15, Stellplatz5, Stellplatz6, ];
  const imgsVert = [Stellplatz7, Stellplatz16,];

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 5, }}>
      <MetaValues title="Unsere Stellplätze" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
        <Typography variant="h2" component="h1" textAlign="center">Unsere Stellplätze</Typography>
        <Grid container spacing={5} sx={{ mt: 3, px: 5 }}>
          <InfoElement icon={WaterIcon} title="Wasser" text="Für alle Stellplätze steht ein Wasseranschluss zur Verfügung" />
          <InfoElement icon={DrainageIcon} title="Abwasser" text="Beim Tinyhof Welzow ist eine Entsorgung des Abwassers möglich" />
          <InfoElement icon={ElectricBoltIcon} title="Strom" text="An den Stellplätzen besteht die Möglichkeit zur Nutzung von 16A-abgesicherten Steckdosen" />
        </Grid>
        <AboutPropertyElement left img={Welzow1} title="Der Hof">Der Tinyhof Welzow liegt inmitten des Lausitzer Seenlandes und ist der ideale Ausgangspunkt für die Erkundung der Region zu Fuß, zu Rad oder mit dem Fahrzeug. Die Stadt Welzow bietet mehrere Einkaufsmöglichkeiten und Dienstleistungen und in den Sommermonaten ein Freibad zur schnellen Abkühlung und Erholung.<br />
          Das Grundstück ist idyllisch, naturnah und ruhig in einer ehemaligen Gartensiedlung gelegen und bietet neben einem Tiny House für einen längeren Aufenthalt drei Wohn- bzw. Reisemobilstellplätze. Die Stellplätze sind naturnah mit Schotter-Rasen befestigt und bieten jeweils einen 16 A Stromanschluss, sowie einen Wasseranschluss. Die Entsorgung von Grau- und Schwarzwasser ist ebenfalls möglich.<br />
        </AboutPropertyElement>
        <AboutPropertyElement img={Stellplatz4} title="Die Wohnmobilstellplätze" >Auf dem 100 m langen Grundstück befinden sich an zwei separat anfahrbaren Standorten mit insgesamt 4 Stellplätze für Wohnmobile, wobei ein Stellplatz den Tiny Houses zugeordnet ist.<br />
          Jeder Stellplatz hat die Möglichkeit zum Anschluss einer 16A-abgesicherten Steckdose. Auf dem Grundstück befindet sich hierzu eine Stromsäule, die zusätzlich auch mit einem Wasseranschluss ausgerüstet ist. Die Kosten hierfür sind bereits in der Stellplatzgebühr enthalten. Das Grundstück ist naturbelassen und bietet je nach Wunsch schattige Bereiche. Die Anordnung entnehmen Sie bitte den Markierungen vor Ort.</AboutPropertyElement>
        <Grid container spacing={2} justifyContent="center" sx={{mt: 5}}>
          {imgs.map(img => (
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component="img"
              src={img}
              alt="Stellplatz"
              sx={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 2 }}
            />
          </Grid>))}
        </Grid>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 1 }}>
          {imgsVert.map(img => (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component="img"
                src={img}
                alt="Stellplatz"
                sx={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 2 }}
              />
            </Grid>))}
        </Grid>
        <Button variant="contained" color="secondary" size="large" onClick={() => handleClick("oJ8MTfafyRl41xaElogp")} sx={{ mt: 10, alignSelf: "center" }}>Stellplatz buchen</Button>
      </Box >
    </Box>
  );
} 