import { Box, Button, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MetaValues from "../../components/MetaValues";

export default function Support() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 5, }}>
      <MetaValues title="Support" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", maxWidth: 800, }}>
        <Typography variant="h2" component="h1" textAlign="center">Support</Typography>
        <Typography mt={2} textAlign="justify">
          Wenn sie Fragen, Probleme oder ein sonstiges Anliegen haben, können Sie uns gerne kontaktieren.
        </Typography>
        <Typography textAlign="justify">
          Auch wenn Sie unsere gespeicherten Daten zu Ihnen einsehen wollen oder Ihre Daten gelöscht werden sollen, können Sie sich ebenfalls gerne an die E-Mail Adresse wenden.
        </Typography>
        <Typography variant="h6" mt={2} textAlign="center">
          Ihr Ansprechpartner vor Ort:<br />
        </Typography>
        <Typography textAlign="center">
            Jörg Möller-Strobel<br />
          Telefon: +49 170 7970885
        </Typography>
        <Typography variant="h6" mt={2} textAlign="center">
            Ihr Ansprechpartner zum Thema Buchung, Stornierung und Datenschutz:<br />
        </Typography>
        <Typography textAlign="center">
          Henry Leukert<br />
          E-Mail:&nbsp;
          <Link href="mailto:support@sl-homes.de">
            support@sl-homes.de
          </Link>
          <br />Telefon: +49 7127 528177
        </Typography>
        <Button variant="contained" color="secondary" size="large" component={RouterLink} to="/" sx={{ mt: 5, alignSelf: "center" }}>Zur Startseite</Button>
      </Box >
    </Box>
  );
}