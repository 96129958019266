import { Grid, Stack, Typography } from "@mui/material";

export default function BookingSection(props) {
  return (
    <Stack sx={{ width: "100%", alignItems: "stretch", paddingX: "5%" }}>
      <Typography variant="h3">{props.title}</Typography>
      {/* <Stack spacing={4} sx={{ width: "100%", alignItems: "stretch", paddingX: 10, pt: 2, pb: 3}}>
        {props.children}
      </Stack> */}
      <Grid container spacing={4} columns={props.fullSize ? { xs: 12, lg: 6 } : 12} alignItems={props.center ? "center" : "flex-start"} sx={{ width: "100%", paddingX: "5%", pt: 2, pb: 3 }}>
        {props.children}
      </Grid>
    </Stack>
  )
}