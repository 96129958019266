import { Alert, Backdrop, Box, Button, CircularProgress, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import { getFunctions, httpsCallable } from "firebase/functions"
import { getApp } from "firebase/app";
import MetaValues from "../components/MetaValues";

export default function UserKeypad() {
  const [pin, setPin] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const functions = getFunctions(getApp(), "europe-west3");
  const checkDoorCode = httpsCallable(functions, "checkDoorCode");

  function handleChange(event) {
    const { value } = event.target;
    setPin(value);
  }

  async function handleSubmit() {
    setLoading(true);
    const res = await checkDoorCode({ pin: pin });
    setLoading(false);
    setSnackbarOpen(true);
    setSuccess(res.data.success);
    setPin("");
  }

  return (
    <Box component="form" sx={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center", mb: 8, p: 5, alignItems: "center", gap: 5 }} onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>

      <MetaValues title="Keypad" />
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 3 }}>
          <CircularProgress />
          <Typography>
            Das kann ein paar Sekunden dauern
          </Typography>
        </Box>
      </Backdrop>
      <Typography textAlign="center" variant="h4">
        Bitte geben Sie den Code aus der Bestätigungsmail ein
      </Typography>

      <TextField disabled={loading} type="password" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} autoComplete="off" value={pin} onChange={handleChange} />
      <Button disabled={loading} variant="contained" size="large" type="submit">Absenden</Button>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity={success ? "info" : "error"} sx={{ width: "100%" }} variant="filled" onClose={() => setSnackbarOpen(false)}>
          {success ? "Eingabe verarbeitet" : "Eingabe abgelehnt"}
        </Alert>
      </Snackbar>
    </Box>
  );
}