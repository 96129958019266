import { Divider, Typography, Link, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import FooterFlag from "./FooterFlag";
import adacFlag from "../../images/ADAC_Card.webp";
import NiceLink from "../NiceLink";
import { getAuth, signOut } from "firebase/auth";

export default function Footer(props) {
  const navigate = useNavigate();
  const auth = getAuth();

  return (
    <Box component="footer">
      <Divider sx={{ mt: 8 }} />
      {/* <Box sx={{ py: 5, px: 3, display: "flex", alignItems: "center", justifyContent: "space-between", gap: 8 }}> */}
      <Grid container alignItems="center" justifyContent="center" columnSpacing={3} rowSpacing={4} sx={{ py: 5, px: 3, }}>
        <Grid item xs={8} md={2} sx={{ display: "flex", flexWrap: "wrap", gap: 1, justifyContent: "center" }}>
          <FooterFlag img={adacFlag} alt="ADAC Campcard" />
        </Grid>
        {/* TODO md has to be 8 to make place for payment methods */}
        <Grid item order={{ xs: 1, md: 0 }} xs={12} md={8} mt={{ xs: 3, md: 0 }} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
          <Typography variant="h6" textAlign="center" sx={{ userSelect: "none" }}>
            <Link variant="inherit" color="inherit" component="div" underline="none" textAlign="center" href="/" onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }} style={{ cursor: "pointer" }}>
              SL Homes
            </Link>
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "end", rowGap: 2, columnGap: 3 }}>
            <NiceLink noUnderline href="/">Startseite</NiceLink>
            {/* <NiceLink noUnderline href="/tinyhof-welzow">Tinyhof Welzow</NiceLink> */}
            <NiceLink noUnderline href="/tinyhof-welzow/stellplaetze">Stellplätze</NiceLink>
            <NiceLink noUnderline external href="https://airbnb.com/h/tiny-house-welzow">Tiny House</NiceLink>
            <NiceLink noUnderline href="/tinyhof-welzow/anfahrt">Anfahrt</NiceLink>
            <NiceLink noUnderline href="/support">Support</NiceLink>
            <NiceLink noUnderline href="/datenschutzerklaerung">Datenschutzerklärung</NiceLink>
            <NiceLink noUnderline href="/agb">AGB</NiceLink>
            <NiceLink noUnderline href="/impressum">Impressum</NiceLink>
            <NiceLink noUnderline href="/" action={() => {
              if (props.loggedIn) {
                signOut(auth).catch((error) => console.log(error));
                navigate("/");
              } else {
                navigate("/auth");
              }
            }}>{!props.loggedIn ? "Login" : "Logout"}</NiceLink>
          </Box>
        </Grid>
        {/* <Grid item xs={8} md={2} sx={{ display: "flex", flexWrap: "wrap", gap: 1, justifyContent: "center" }}>
          <FooterFlag img={adacFlag} alt="ADAC Campcard" small />
          <FooterFlag img={adacFlag} alt="ADAC Campcard" small />
          <FooterFlag img={adacFlag} alt="ADAC Campcard" small />
          <FooterFlag img={adacFlag} alt="ADAC Campcard" small />
          <FooterFlag img={adacFlag} alt="ADAC Campcard" small />
        </Grid> */}
      </Grid>
    </Box>
  );
}

/* function AccountElement(props) {
  const navigate = useNavigate();
  const auth = getAuth();

  function handleButton() {
    if (props.loggedIn) {
      signOut(auth).catch((error) => console.log(error));
      navigate("/");
    } else {
      navigate("/auth");
    }
  }

  if (props.loggedIn) {
    return (
      <Button color="inherit" onClick={handleButton}>Logout</Button>
    );
  } else {
    return (
      <Button color="inherit" onClick={handleButton}>Login</Button>
    );
  }
} */