import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import MetaValues from "../../components/MetaValues";

export default function BookingSuccessful() {
  return (
    <Box sx = {{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <MetaValues title="Buchung erfolgreich" />
      <Box sx={{ p: 5 }}>
        <Typography variant="h2" component="h1" textAlign="center">Buchung erfolgreich</Typography>
        <Typography mt={4}>
          Vielen Dank! Ihre Buchung wurde erfolgreich getätigt.<br/>Sie erhalten bald eine E-Mail mit Ihrer Rechnung, welche die Buchung bestätigt. Bitte schauen Sie auch in Ihrem Spam-Ordner nach.<br/><br/>Wir freuen uns auf Ihren Aufenthalt!
        </Typography>
      </Box>
      <Button variant="contained" color="secondary" size="large" component={RouterLink} to="/">Zur Startseite</Button>
    </Box >
  );
}