import { Button, ButtonGroup, Checkbox, Divider, FormControl, FormControlLabel, Paper, Skeleton, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Flatpickr from "react-flatpickr";
import { German } from "flatpickr/dist/l10n/de";
import "flatpickr/dist/themes/light.css";
import { collection, getDocs, getFirestore, onSnapshot, query, where, doc, getDoc, orderBy } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import NiceLink from "../../components/NiceLink";
import MetaValues from "../../components/MetaValues";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import BookingSection from "../../components/booking/BookingSection";
import BookingElement from "../../components/booking/BookingElement";
import Lageplan from "../../images/Lageplan.webp";


const costPerNight = 15;
const costPerNightLong = 12;
const costEnergyPerNight = 3;

function calcNights(selectedDates) {
  if (!selectedDates[1])
    return 0;

  let nights = 0;
  nights = Math.ceil((selectedDates[1].getTime() - selectedDates[0].getTime()) / (1000 * 60 * 60 * 24))
  if (nights < 0)
    nights = 0;

  return nights;
}

let constData = {};

export default function PropertyBooking(props) {
  const [buyEnergy, setBuyEnergy] = React.useState(false);
  const [maxDay, setMaxDay] = React.useState("");
  const [plots, setPlots] = React.useState([]);
  const [selectablePlots, setSelectablePlots] = React.useState([]);
  const [plotDatesMap, setPlotDatesMap] = React.useState([]);
  const [selectedPlot, setSelectedPlot] = React.useState("");
  const [blockedDays, setBlockedDays] = React.useState([]);
  const [timelines, setTimelines] = React.useState([]);
  const [selectedDates, setSelectedDays] = React.useState([]);
  const [calEdit, setCalEdit] = React.useState(false);
  const [currentPrice, setCurrentPrice] = React.useState(0);
  /*   const [loading, setLoading] = React.useState(false); */
  const [bookingPossible, setBookingPossible] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [hasAdac, setHasAdac] = React.useState(false);
  const [adacAvailable, setAdacAvailable] = React.useState(false);
  const [licensePlate, setLicensePlate] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [currentNights, setCurrentNights] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [areaData, setAreaData] = React.useState({
    name:
      "..."
  });

  const functions = getFunctions(getApp(), "europe-west3");
  const paypalCreateOrder = httpsCallable(functions, "paypalCreateOrder");
  const paypalHandleOrder = httpsCallable(functions, "paypalHandleOrder");

  const navigate = useNavigate();
  const theme = useTheme();

  constData = {
    selectedDates: [...selectedDates],
    buyEnergy,
    hasAdac,
    adacAvailable,
    selectedPlot,
    licensePlate
  };

  let areaId;
  const location = useLocation();
  if (location.state) {
    areaId = location.state.areaId;
  } /*else if (props.location) {
    areaId = props.location;
  }*/ else {
    //TODO multiple locations
    navigate("/stellplaetze/standorte");

    /* areaId = "oJ8MTfafyRl41xaElogp"; */
  }

  const db = getFirestore();

  //TODO refactor useEffects and combine where possible

  React.useEffect(() => {
    //get used area
    async function getArea() {
      const areaSnap = await getDoc(doc(db, "areas", areaId));
      setAreaData(areaSnap.data());
    }

    getArea();
  }, [db, areaId]);

  React.useEffect(() => {
    console.log("E3");
    async function getPlots() {
      //Get available plots
      const q = query(collection(db, "plots"), where("bookable", "==", true), where("area", "==", areaId), orderBy("shortname"));

      const snap = await getDocs(q);
      const tempPlots = [];
      snap.forEach((doc) => {
        tempPlots.push(doc);
      });
      setPlots(tempPlots);
    }

    getPlots();
  }, [db, areaId]);

  React.useEffect(() => {
    console.log("E2");

    if (plots.length === 0)
      return;

    //Get bookings
    return onSnapshot(query(collection(db, "public/bookings/timelines"), where("area", "==", areaId)), (snap) => {
      const tTimelines = [];
      snap.forEach(t => tTimelines.push({
        id: t.id,
        list: t.data().used.map(ts => ts.toDate()),
      }));

      setTimelines(tTimelines);

      const tBlockedDates = [];
      tTimelines[0].list.forEach(date => {
        let possible = false;
        tTimelines.forEach(tl => {
          if (tl === tTimelines[0])
            return;

          let included = false;
          tl.list.forEach(d2 => {
            if (date.getTime() === d2.getTime())
              included = true;
          });

          if (!included) {
            possible = true;
            return;
          }
        });
        if (!possible) {
          tBlockedDates.push(date);
        }
      });

      setBlockedDays(tBlockedDates);
    });
  }, [db, plots, areaId]);

  React.useEffect(() => {
    if (selectedDates.length !== 1 || !calEdit) {
      setMaxDay(null);
      return;
    }

    //calculate max days
    function dateNotInList(date, list) {
      date.setUTCHours(12, 0, 0, 0);
      let included = false;
      list.forEach(d => {
        d.setUTCHours(12, 0, 0, 0);
        if (d.getTime() === date.getTime()) {
          included = true;
          return;
        }
      });
      return !included;
    }

    //check if startDate is valid
    if (!dateNotInList(selectedDates[0], blockedDays)) {
      setCalEdit(prev => !prev);
      setSelectedDays([]);
      return;
    }

    const compMap = [];

    timelines.forEach(tl => {
      let max = 0;
      const maxDate = new Date(selectedDates[0].getTime());
      while (max < 1000 && dateNotInList(maxDate, tl.list)) {
        maxDate.setUTCDate(maxDate.getUTCDate() + 1);
        max += 1;
      }
      /* maxDate.setDate(maxDate.getDate() - 1); */
      if (max > 0) {
        compMap.push({
          timeline: tl,
          max: max,
          maxDate: maxDate,
        });
      }
    });

    let biggestDate = compMap[0];
    /* console.log("BD: " + biggestDate.max); */
    compMap.forEach(d => {
      /* console.log("ND: " + d.max); */
      if (d.max > biggestDate.max)
        biggestDate = d;
    })

    setMaxDay(biggestDate.maxDate);
    setSelectedPlot(biggestDate.timeline.id);
    setPlotDatesMap(compMap);
  }, [selectedDates, timelines, calEdit, blockedDays]);

  function getBlockedDays() {
    let bd = [];
    if (!calEdit) {
      if (selectedDates.length > 1) {
        bd = blockedDays.filter(d => {
          return d.getTime() !== selectedDates[selectedDates.length - 1].getTime();
        })
      } else {
        bd = blockedDays
      }
    } else {
      bd = [];
    }
    return bd;
  }

  React.useEffect(() => {
    //calculate current price && adac availability

    if (selectedDates.length < 2 || calEdit) {
      return;
    }

    //calculate nights amount
    const nights = calcNights(selectedDates);
    setCurrentNights(nights);

    let finalPrice = (
      Math.min(2, nights) * costPerNight
      + Math.max(0, nights - 2) * costPerNightLong
      + ((buyEnergy ? 1 : 0) * nights * costEnergyPerNight))
      * ((hasAdac && adacAvailable) ? 0.8 : 1);
    finalPrice = Math.round(finalPrice * 100) / 100;
    setCurrentPrice(finalPrice);

    let fullPrice = (
      nights * costPerNight
      + (buyEnergy ? 1 : 0) * nights * costEnergyPerNight
    );
    setDiscount(fullPrice - finalPrice);

    function inAdacZone(date) {
      const month = date.getMonth();
      const day = date.getDate();

      if (month === 1 || month === 2 || month === 10 || (month === 0 && day >= 15) || (month === 9 && day >= 15))
        return true;
      else
        return false;
    }

    const startDate = new Date(selectedDates[0]);
    const endDate = new Date(selectedDates[selectedDates.length - 1]);

    /* console.log("Start") */
    setSelectablePlots(plots.map(plot => {
      if (plot.id === selectedPlot) return plot.id;

      /* console.log(plot.id + " 2")
      console.log(plotDatesMap) */
      const pMap = plotDatesMap.find(pdm => pdm.timeline.id === plot.id);
      /* console.log(pMap) */
      if (!pMap) {
        return null;
      }
      if (pMap.maxDate >= endDate) {
        return plot.id;
      }
      return null;
    }));

    if (inAdacZone(startDate) && inAdacZone(endDate) && inAdacZone(new Date((startDate.getTime() + endDate.getTime()) / 2)))
      setAdacAvailable(true);
    else
      setAdacAvailable(false);
  }, [buyEnergy, selectedDates, hasAdac, adacAvailable, plotDatesMap, selectedPlot, calEdit, plots])

  function handlePlotChange(event, newPlot) {
    if (newPlot != null)
      setSelectedPlot(newPlot);
    console.log(newPlot)
  }

  async function handleBookingPaypal(data, actions) {
    const auth = getAuth();
    let user = auth.currentUser;

    let signedInProm;
    if (!user) {
      signedInProm = signInAnonymously(auth);
    }

    if (signedInProm) {
      const userCred = await signedInProm;
      user = userCred.user;
    }

    console.log(constData.selectedPlot)

    return await paypalCreateOrder({
      dateStart: constData.selectedDates[0].getTime(),
      dateEnd: constData.selectedDates[constData.selectedDates.length - 1].getTime(),
      selectedPlot: constData.selectedPlot,
      licensePlate: constData.licensePlate,
      buyEnergy: constData.buyEnergy,
      hasAdac: constData.hasAdac && constData.adacAvailable,
    }).then(res => res.data.id);
  }

  React.useEffect(() => {
    //Error text
    if (!bookingPossible && !acceptedTerms && licensePlate.length === 0)
      return;

    if (!bookingPossible) {
      setErrorText("Bitte wählen Sie einen Buchungszeitraum");
      return;
    }
    if (licensePlate.length === 0) {
      setErrorText("Bitte tragen Sie Ihr Fahrzeugkennzeichen in das entsprechenden Feld ein");
      return;
    }
    if (!acceptedTerms) {
      setErrorText("Bitte lesen und akzeptieren Sie unsere AGB und Datenschutzerklärung");
      return;
    }

    setErrorText("");
  }, [licensePlate, bookingPossible, acceptedTerms,])

  return (
    /* AcXwRfE0oWdgIrtiw5-aM9U5EhPzOvm_lzahe_4WXkX9mnWXGRpwOjmPRz86nkVkw8JF3HfCMm52aPmA */
    <PayPalScriptProvider options={{ clientId: "AUhS5njop1AYZhQXZfmv3sAOUExkBcTzwX_KhSRGYvKcLF7nmACFN1FzqbvMO5EvppUKGB4PCbMO6RzS", currency: "EUR" }}>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <MetaValues title="Buchung" />
        {/* <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 3 }}>
            <CircularProgress />
            <Typography>
              Das kann ein paar Sekunden dauern
            </Typography>
          </Box>
        </Backdrop> */}
        <Box sx={{ py: 5 }}>
          <Typography variant="h2" component="h1" textAlign="center">Stellplatz in {areaData.name} buchen</Typography>
        </Box>
        <Box sx={{ p: 5, maxWidth: 700 }}>
          <Typography variant="subtitle1" component="h1" textAlign="center">Wir freuen uns über Ihr Interesse an einem Stellplatz auf unserem Tinyhof in {areaData.name}! Über diese Seite können Sie komfortabel einen Platz ganz nach Ihren Wünschen buchen.</Typography>
        </Box>
        <Stack>
          <Stack divider={<Divider />} spacing={3} sx={{ pt: 3, width: "100%", alignItems: "start", paddingX: "5%" }}>
            <BookingSection title="Daten">
              <BookingElement title="Zeitraum:">
                <Stack alignItems="start">
                  <Flatpickr
                    value={selectedDates}
                    onChange={(sd, dateStr, instance) => {
                      sd.forEach(d => {
                        d.setHours(12, 0, 0, 0);
                        d.setUTCHours(12, 0, 0, 0);
                      });
                      setCalEdit(prev => !prev);
                      setSelectedDays(sd);
                      if (sd.length > 1 && sd[0].getTime() !== sd[1].getTime())
                        setBookingPossible(true);
                      else
                        setBookingPossible(false);
                    }}
                    options={{
                      mode: "range",
                      inline: true,
                      locale: German,
                      altInput: true,
                      altInputClass: "invisible",
                      minDate: !calEdit ? "today" : selectedDates[0],
                      maxDate: !calEdit ? null : maxDay,
                      disable: getBlockedDays(),
                    }}
                  />
                  <Typography sx={{ mt: 1 }} textAlign="start">Bitte wählen Sie das Anfangs- und Enddatum Ihrer Buchung. Alle auswählbaren Tage sind noch verfügbar.</Typography>
                </Stack>
              </BookingElement>
              <BookingElement title="Fahrzeugkennzeichen:">
                <TextField required label="Fahrzeugkennzeichen" variant="filled" color="secondary" value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)} sx={{
                  maxWidth: 220,
                  '& .MuiFilledInput-root': {
                    color: "black",
                    backgroundColor: 'white'
                  } }} />
              </BookingElement>
            </BookingSection>

            <BookingSection title="Optionen">
              <BookingElement title="Stromzugang buchen:">
                <ButtonGroup
                  aria-label="Strombuchung"
                  color="secondary">
                  <Button variant={!buyEnergy ? "contained" : "outlined"}
                    onClick={() => setBuyEnergy(false)}
                  >Nein</Button>
                  <Button variant={buyEnergy ? "contained" : "outlined"}
                    onClick={() => setBuyEnergy(true)}
                  >Ja (+{costEnergyPerNight}€ pro Nacht)</Button>
                </ButtonGroup>
              </BookingElement>

              <BookingElement title="Stellplatz wählen:">
                <Box
                  component="img"
                  src={Lageplan}
                  alt="Stellplatz"
                  sx={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 1 }}
                />
                {
                  plots.length > 0 ? (
                    <ToggleButtonGroup size="large" exclusive value={selectedPlot} onChange={handlePlotChange}>
                      {plots.map(plot => (
                        <ToggleButton value={plot.id} disabled={!selectablePlots.includes(plot.id)}>
                          {plot.data().shortname}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  ) :
                    (
                      <Skeleton variant="rounded" width={210} height={60} />
                    )
                }
              </BookingElement>
            </BookingSection>

            <BookingSection title="Rabatte" center>
              <BookingElement>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox color="secondary" checked={hasAdac && adacAvailable} disabled={!adacAvailable} onChange={(e) => setHasAdac(e.target.checked)} />}
                    label={<Typography>[Optional] Ich besitze eine ADAC Campcard und möchte 20% Rabatt einlösen (gültig 15.01. - 31.03. sowie 15.10. - 30.11., Karte ist auf Verlangen vorzuzeigen)</Typography>}
                  />
                </FormControl>
              </BookingElement>
              <BookingElement>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox color="secondary" checked={currentNights >= 3} disabled={currentNights < 3} />}
                    label={<Typography>Rabatt auf {costPerNightLong}€ pro Nacht ab der dritten Nacht</Typography>}
                  />
                </FormControl>
              </BookingElement>
            </BookingSection>

          </Stack>
          <Paper variant="outlined" sx={{ width: "100vw", mt: 3 }}>
            <Stack divider={<Divider />} spacing={3} sx={{ pt: 3, width: "100%", alignItems: "stretch", paddingX: "5%" }}>
              <BookingSection title="Zusammenfassung">
                <BookingElement title="Preise">
                  <Typography component="p">Grundkosten: <b>{costPerNight}€ / Nacht</b></Typography>
                  <Typography component="p">Energiekosten: <b>{costEnergyPerNight}€ / Nacht</b></Typography>
                </BookingElement>
                <BookingElement title="Ihre Buchung">
                  <Typography component="p" sx={{ mt: 2 }}>Nächte:  <b>{currentNights}</b></Typography>
                  <Typography component="p" sx={{ mt: 0 }}>Strom:  <b>{buyEnergy ? "Ja" : "Nein"}</b></Typography>
                </BookingElement>
                <BookingElement>
                  <Typography>Nach Abzug von Rabatten in Höhe von {(Math.round(discount * 100) / 100).toFixed(2)}€</Typography>
                  <Typography variant="h5" component="p" sx={{ mt: 1 }}>Gesamtpreis: <b>{(Math.round(currentPrice * 100) / 100).toFixed(2)}€</b></Typography>
                  <Typography variant="caption">inkl. MwSt.</Typography>
                </BookingElement>
              </BookingSection>
              <BookingSection title="Bezahlung & Buchung">
                <BookingElement>
                  <FormControl>
                    <FormControlLabel
                      control={<Checkbox color="secondary" checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} />}
                      label={<Typography>Ich akzeptiere die aktuell gültigen <NiceLink href="/agb">AGB</NiceLink> und die aktuell gültige <NiceLink href="/datenschutzerklaerung">Datenschutz&shy;erklärung</NiceLink> von SL Homes</Typography>}
                    />
                  </FormControl>
                  {/* <Button sx={{ mt: 3, mb: 3 }} variant="contained" color="secondary"
            onClick={handleBook} disabled={!bookingPossible || !acceptedTerms || licensePlate.length === 0} size="large">Zahlungspflichtig buchen</Button> */}
                  <Typography variant="subtitle2" color={theme.palette.error.dark} textAlign="center"><b>{errorText}</b></Typography>
                </BookingElement>
                <BookingElement>
                  <Box sx={{ maxWidth: 400 }}>
                    <PayPalButtons
                      disabled={!bookingPossible || !acceptedTerms || licensePlate.length === 0}
                      style={{ label: "buynow" }}
                      createOrder={handleBookingPaypal}
                      onApprove={(data, actions) => {
                        paypalHandleOrder({ orderId: data.orderID })
                        navigate("/status/success");
                      }}
                    />
                  </Box>
                </BookingElement>
              </BookingSection>
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </PayPalScriptProvider>
  );
}