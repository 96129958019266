import { Box, Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import MetaValues from "../../components/MetaValues";

export default function AGB() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 5, }}>
      <MetaValues title="Impressum" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", maxWidth: 800, rowGap: 3 }}>
        <Typography variant="h2" component="h1" textAlign="center" sx={{ mb: 3 }}>Impressum</Typography>
        <Typography textAlign="center">
          Henry Leukert<br />
          Tübinger Straße 32<br />
          72666 Neckartailfingen<br />
          Tel 07127 528177<br />
          support@sl-homes.de
        </Typography>
        <Typography>
          Hinweis zu allen Links auf dieser Webpräsenz:
        </Typography>
        <Typography>
          Mit Urteil vom 12. Mai 1998 - 312 0 85/98 - "Haftung für Links" hat das Landgericht (LG) Hamburg entschieden, daß man durch die Erstellung eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten hat. Dies kann - so das Gericht - nur dadurch verhindert werden, daß man sich ausdrücklich von diesen Inhalten distanziert. Da wir ein URL-Link-Archiv betreiben und keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten haben, distanzieren wir uns ausdrücklich von allen Inhalten aller gelinkten Seiten und machen uns die Inhalte der gelinkten Seiten nicht zu eigen. Diese Erklärung gilt für alle auf diesem Server verfügbaren Links sowie evtl. vorhandenen Linklisten und Foren, die zur Zeit aktiv sind und/oder aktiv werden.
        </Typography>
        <Typography>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: <Link href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener">https://ec.europa.eu/consumers/odr/</Link>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
        </Typography>

        <Typography variant="h5">
          Haftungsausschluß:
        </Typography>
        <Typography>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </Typography>
        <Button variant="contained" color="secondary" size="large" onClick={() => { navigate("/"); }} sx={{ mt: 5, alignSelf: "center" }}>Zur Startseite</Button>
      </Box >
    </Box >
  );
}