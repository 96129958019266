import { Box, Grid, Typography } from "@mui/material";

export default function InfoElement(props) {
  return (
    <Grid item xs={12} sm={4}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 1}}>
        {<props.icon sx={{ fontSize: 60 }} />}
        <Typography variant="h3" textAlign="center">
          {props.title}
        </Typography>
        <Typography textAlign="center">
          {props.text}
        </Typography>
      </Box>
    </Grid>
  );
}