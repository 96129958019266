import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LandingElement(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ py: 5 }}>
      <Grid container spacing={0} alignItems="stretch" columns={24} sx={{ mx: "auto", boxShadow: "0px 0px 28px rgba(0,0,0,0.4)", borderRadius: "20px", overflow: "hidden" }}>
        {props.img &&
          <Grid item xs={24} md={9} order={{ xs: 0, md: props.left ? 1 : 0 }}>
            <Box
              component="img"
              src={props.img}
              alt="Welzow"
              sx={{ objectFit: "cover", aspectRatio: "3/2", width: "100%", height: "100%" }}
            />
          </Grid>}
        <Grid item xs={24} md={props.img ? 15 : 24} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", py: 5, px: 3, gap: 5, backgroundColor: theme.palette.background.paper }}>
          <Typography variant='h2' align='center'>{props.title}</Typography>
          <Typography variant='subtitle1' align='center'>{props.children}</Typography>
          <Box sx={{ display: "flex", gap: 5 }}>
            {props.hrefA
              ?
              <Button variant="contained" color="secondary" size="large" target="_blank" href={props.btnALink} rel="noopener">{props.btnAText}</Button>
              :
              <Button variant="contained" color="secondary" size="large" onClick={() => navigate(props.btnALink)}>{props.btnAText}</Button>
            }
            {props.btnBText && (
              props.hrefB
                ?
                <Button variant={props.bPrimary ? "contained" : "outlined"} color="secondary" size="large" target="_blank" href={props.btnBLink} rel="noopener">{props.btnBText}</Button>
                :
                <Button variant={props.bPrimary ? "contained" : "outlined"} color="secondary" size="large" onClick={() => navigate(props.btnBLink)}>{props.btnBText}</Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}