import { Divider, Drawer, Typography } from "@mui/material";
import AppDrawerListItem from "./AppDrawerListItem";
import { Home, AdminPanelSettings, PrivacyTip, BusinessCenter, Help, AirportShuttle, Directions, House  } from "@mui/icons-material"
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";

export default function AppDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();

  function targetClicked(target) {
    if (location.pathname !== target) {
      navigate(target);
    }
    props.toggleDrawer();
  }

  return (
    <nav>
      <Drawer
        open={props.drawerOpen}
        onClose={props.toggleDrawer}>
        <Box sx={{ width: 270, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", height: 150, px: 3, alignItems: "center", justifyContent: "center", gap: 2 }}>
            {/* <Box sx={{ flex: 1, minWidth: 0 }}>
            <img src={logo} alt="logo" style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </Box> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 /*maxWidth: "60%"*/ }}>
              <Typography variant="h3" component="h1" textAlign="center">SL Homes</Typography>
              <Typography variant="caption" textAlign="center">Nachhaltige Wohnmobilstellplätze und Tiny Homes</Typography>
            </Box>
          </Box>
          <Divider />
          <AppDrawerListItem text={"Home"} icon={<Home />} target={"/"} handleClick={targetClicked} location={location} />
          {/* <AppDrawerListItem text={"Tinyhof Welzow"} icon={<Place />} target={"tinyhof-welzow"} handleClick={targetClicked} location={location} /> */}
          <AppDrawerListItem text={"Stellplätze"} icon={<AirportShuttle />} target={"tinyhof-welzow/stellplaetze"} handleClick={targetClicked} location={location} />
          <AppDrawerListItem text={"Tiny Home"} icon={<House />} target={"https://airbnb.com/h/tiny-house-welzow"} external handleClick={targetClicked} location={location} />
          <AppDrawerListItem text={"Anfahrt"} icon={<Directions />} target={"tinyhof-welzow/anfahrt"} handleClick={targetClicked} location={location} />

          {props.isAdmin === 1 &&
            <Divider />}
          {props.isAdmin === 1 &&
            <AppDrawerListItem text={"Admin"} icon={<AdminPanelSettings />} target={"/admin"} handleClick={targetClicked} location={location} />}
          
          <Divider />
          <AppDrawerListItem text={"Support"} icon={<Help />} target={"/support"} handleClick={targetClicked} location={location} />
          <AppDrawerListItem text={"Datenschutzerklärung"} icon={<PrivacyTip />} target={"/datenschutzerklaerung"} handleClick={targetClicked} location={location} />
          <AppDrawerListItem text={"Impressum"} icon={<BusinessCenter />} target={"/impressum"} handleClick={targetClicked} location={location} />
        </Box>
      </Drawer>
    </nav>
  );
}