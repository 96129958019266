import { Box, Typography } from "@mui/material";

export default function LawElement(props) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, mt: 2 }} >
      <Typography variant={props.lower ? "h5" : "h4"}>
        {props.title}
      </Typography>
      {props.children}
    </Box>
  );
}