import { Helmet } from "react-helmet";
import React from "react";

export default function MetaValues(props) {
  return (
    <Helmet>
      <title>SL Homes - {props.title}</title>
      {props.description && <meta name="description" content={props.description} />}
    </Helmet>
  );
}