import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function PropertyLocation(props) {
  const navigate = useNavigate();

  function handleClick(areaId) {
    navigate("/stellplaetze/buchen", { state: { areaId } });
  }

  return (
    <Grid
      container
      spacing={4}
      alignItems="center"
      direction={props.left ? "row" : "row-reverse"}>
      <Grid item xs={12} sm={6} md={5}>
        <Box
          component="img"
          src={props.img}
          alt="Stellplatz"
          sx={{objectFit: "cover", width: "100%", height: "100%", borderRadius: 8}}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={7}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 4}}>
          <Typography variant="h3" >{props.title}</Typography>
          <Typography>{props.description}</Typography>
          {/* <Button variant="contained" color="secondary" onClick={props.onClick}>Buchung</Button> */}
          <Box sx={{ display: "flex", gap: 5 }}>
            <Button variant="contained" color="secondary" size="large" onClick={() => handleClick(props.areaId)}>Buchen</Button>
            <Button variant="outlined" color="secondary" size="large" onClick={() => navigate(props.aboutPage)}>Mehr</Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}