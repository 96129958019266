import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate, } from "react-router-dom"
import landingImage from "../images/Stellplatz (17).webp";
import stellplatzImage from "../images/Stellplatz (4).webp";
import tinyhouseImage from "../images/Con1.webp";
import LandingElement from '../components/landing/LandingElement';
import InfoElement from '../components/landing/InfoElement';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterIcon from '@mui/icons-material/Water';
import ForestIcon from '@mui/icons-material/Forest';
import MetaValues from '../components/MetaValues';

export default function Landing() {
  const navigate = useNavigate();
  const theme = useTheme();

  function handleClick(areaId) {
    navigate("/stellplaetze/buchen", { state: { areaId } });
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <MetaValues title="Stellplätze in Welzow" description="Wir vermieten nachhaltige und naturnahe Stellplätze auf unserem Tinyhof in Welzow. Durch einen Strom- und Wasseranschluss ist für alle Bedürfnisse gesorgt." />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", px: "6%", minHeight: "93vh" }}>
        <Grid container spacing={0} alignItems="stretch" sx={{ flexGrow: 1, my: "5%", maxWidth: 1300, boxShadow: "0px 0px 28px rgba(0,0,0,0.4)", borderRadius: "20px", overflow: "hidden" }} columns={24}>
          <Grid item xs={24} md={13}>
            <Box
              component="img"
              src={landingImage}
              alt="Welzow"
              sx={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid item xs={24} md={11} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10%", py: 5, px: 2, backgroundColor: theme.palette.background.paper }}>
            <Typography variant='h1' align='center'>Stellplätze Welzow</Typography>
            <Typography variant='h5' component="h2" align='center'>Nachhaltige Wohnmobilstellplätze und Tiny Homes</Typography>
            <Box sx={{ display: "flex", gap: 5 }}>
              <Button variant="contained" color="secondary" size="large" onClick={() => handleClick("oJ8MTfafyRl41xaElogp")}>Buchen</Button>
              <Button variant="outlined" color="secondary" size="large" onClick={() => navigate("/tinyhof-welzow/stellplaetze")}>Mehr</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Grid container justifyContent="center">
        <Grid item xs={11} md={9}>
          <Grid container justifyContent="space-between" spacing={5} sx={{ mt: 2, mb: 8 }}>
            <InfoElement icon={ElectricBoltIcon} title="Strom" text="An den Stellplätzen sowie im Tiny House besteht die Möglichkeit zur Nutzung von 16A-abgesicherten Steckdosen" />
            <InfoElement icon={WaterIcon} title="Wasser" text="Die Tiny Houses werden selbstverständlich mit Wasser versorgt. Eine Säule versorgt zusätzlich alle Stellplätze" />
            <InfoElement icon={ForestIcon} title="Nachhaltig&shy;keit" text="Durch unsere künftige Solaranlage und die Naturbelassenheit des Grundstücks ist Ihr Urlaub besonders umweltfreundlich" />
          </Grid>
          <LandingElement img={stellplatzImage} title="Stellplätze" btnAText="Buchen" btnALink="/s/welzow" btnBText="Mehr" btnBLink="/tinyhof-welzow/stellplaetze">Auf dem 100 m langen Grundstück befinden sich an zwei separat anfahrbaren Standorten insgesamt 4 Stellplätze für Wohnmobile, wobei ein Stellplatz den Tiny Houses zugeordnet ist. Jeder der Stellplätze hat Zugang zu einem Wasser- und Stromanschluss</LandingElement>
          <LandingElement left hrefA hrefB bPrimary img={tinyhouseImage} title="Tiny Houses" btnAText="Tiny House A" btnALink="https://airbnb.com/h/tiny-house-welzow" btnBText="Tiny House B" btnBLink="https://www.airbnb.de/rooms/947696272218550486">Zwei neue und moderne Tiny Houses auf ruhigem Naturgrundstück. Komm an diesem unvergleichlichen Rückzugsort wieder in Einklang mit der Natur. Die Tiny Houses sind Containerhäuser und basieren auf 20-Fuss-Containern. Sie sind jeweils für 1-2 Personen geeignet und bieten ein Bad mit begehbarer Dusche</LandingElement>
          <LandingElement title="Fragen?" btnAText="Kontakt" btnALink="support">Kontaktieren Sie uns gerne per E-Mail an <Link href="mailto:support@sl-homes.de">support@sl-homes.de</Link> oder in dringenden Angelegenheiten vor Ort unter der +49 170 7970885</LandingElement>
        </Grid>
      </Grid>
    </Box>
  );
}
