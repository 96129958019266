import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function NoMatch() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/");
  })

  return (
    <Typography>Sie werden weitergeleitet, bitte warten</Typography>
  );
}