import { Box, Button, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MetaValues from "../../components/MetaValues";
import Einfahrt1 from "../../images/Stellplatz (8).webp";
import Einfahrt2 from "../../images/Stellplatz (2).webp";
import Lageplan from "../../images/Lageplan.webp";

export default function TinyhofWelzowAddress() {
  const imgs = [Einfahrt2, Einfahrt1];

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 5, }}>
      <MetaValues title="Anfahrt Tinyhof Welzow" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", maxWidth: 800, }}>
        <Typography variant="h2" component="h1" textAlign="center" sx={{ mb: 2 }}>Anfahrt</Typography>
        <Typography textAlign="center">
          Tinyhof Welzow<br />
          Spremberger Straße 98<br />
          03119 Welzow<br /><br />
          Der Stellplatz A1 ist direkt von der Spremberger Straße anfahrbar.<br />
          Die Plätze A2, A3 und A4 erreichen Sie über den Amselweg. Hierzu fahren Sie die Straße ca. 300 m weiter und biegen 2x rechts ab. Nach 300 m erreichen Sie auf der rechten Seite die befestigte Wiese mit den Stellplätzen.
        </Typography>
        <Box
          component="img"
          src={Lageplan}
          alt="Stellplatz"
          sx={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 2, mt: 5 }}
        />
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 0 }}>
          {imgs.map(img => (
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src={img}
                alt="Stellplatz"
                sx={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 2 }}
              />
            </Grid>))}
        </Grid>
        <Button variant="contained" color="secondary" size="large" component={RouterLink} to="/" sx={{ mt: 8, alignSelf: "center" }}>Zur Startseite</Button>
      </Box >
    </Box>
  );
}