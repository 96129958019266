import { Alert, Collapse } from "@mui/material";

export default function LoginAlert(props) {

  return (
    <Collapse in={props.open}>
      <Alert severity="error">
        {props.text}
      </Alert>
    </Collapse>
  );
}