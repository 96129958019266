import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import MetaValues from "../../components/MetaValues";

export default function BookingCanceled() {
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <MetaValues title="Buchung fehlgeschlagen" />
      <Box sx={{ p: 5 }}>
        <Typography variant="h2" component="h1" textAlign="center">Buchung abgebrochen</Typography>
        <Typography mt={2}>
          Ihre Buchung wurde leider abgebrochen. Bitte versuchen Sie es erneut!
        </Typography>
      </Box>
      <Button variant="contained" color="secondary" size="large" component={RouterLink} to="/">Zur Startseite</Button>
    </Box >
  );
}