import { Box, Typography } from "@mui/material";
import MetaValues from "../../components/MetaValues";
/* import { useNavigate } from "react-router-dom"; */
import PropertyLocation from "../../components/PropertyLocation";
import rvImg from "../../images/Stellplatz (4).webp"



export default function PropertyList() {
  /* const navigate = useNavigate();

  function handleClick(areaId) {
    navigate("/stellplaetze/buchen", { state: { areaId } });
  } */

  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch", p: 5 }}>
      <MetaValues title="Unsere Standorte" />
      <Typography variant="h2" component="h1" textAlign="center" sx={{ mb: 5 }}>Unsere Standorte</Typography>
      <PropertyLocation
        title="Welzow"
        description="Der Tinyhof Welzow liegt inmitten des Lausitzer Seenlandes und ist der ideale Ausgangspunkt für die Erkundung der Region zu Fuß, zu Rad oder mit dem Fahrzeug. Die Stadt Welzow bietet mehrere Einkaufsmöglichkeiten und Dienstleistungen und in den Sommermonaten ein Freibad zur schnellen Abkühlung und Erholung."
        img={rvImg}
        areaId="oJ8MTfafyRl41xaElogp"
        aboutPage="/tinyhof-welzow"
        left
      />
      {/* <Divider sx={{ my: 5 }} />
      <PropertyLocation
        title="Standort 2"
        description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. "
        img={rvImg}
        onClick={() => handleClick("LmDqVRT4d7MJwd2yvobj")}
      /> */}
    </Box >
  );
}