import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function AboutPropertyElement(props) {
  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={8}
      alignItems="center"
      direction={props.left ? "row" : "row-reverse"}
    mt={3}>
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center">{props.title}</Typography>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 5}}>
          <Box
            component="img"
            src={props.img}
            alt="Stellplatz"
            sx={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: 3 }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={7}>
        {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center", gap: 1 }}> */}
          {/* {props.items.map(t => ( */}<Typography>{props.children}</Typography>{/* ))} */}
        {/* </Box> */}
      </Grid>
    </Grid>
  );
}