import React, { lazy, Suspense } from 'react';
import { createRoot } from "react-dom/client"
import './index.css';
import '@fontsource-variable/montserrat'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
/* import Admin from "./routes/Admin"; */
import Landing from './routes/Landing';
import Signin from './routes/auth/Signin';
import Signup from './routes/auth/Signup';
import Impressum from './routes/law/Impressum';
import Datenschutzerklaerung from './routes/law/Datenschutzerklaerung';
import UserKeypad from './routes/UserKeypad';
import PropertyBooking from './routes/properties/PropertyBooking';
import BookingCanceled from './routes/info/BookingCanceled';
import BookingSuccessful from './routes/info/BookingSuccessful';
import PropertyList from './routes/properties/PopertyList';
import Support from './routes/info/Support';
import NoMatch from './routes/NoMatch';
import ScrollToTop from './components/ScrollToTop';
import AGB from './routes/law/AGB';
import PropertyRedirect from './routes/properties/PropertyRedirect';
import TinyhofWelzowStellplaetze from './routes/info/TinyhofWelzowStellplaetze';
import TinyhofWelzowAddress from './routes/info/TinyhofWelzowAddress';
/* import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react'; */

const container = document.getElementById('root');
const root = createRoot(container);
/* const matomoInstance = createInstance({
  urlBase: 'https://analytics.sl-homes.de',
  siteId: 1,
}); */

const Admin = lazy(() => import("./routes/Admin"));

root.render(
  <React.StrictMode>
{/*     <MatomoProvider value={matomoInstance}> */}
      <BrowserRouter>
        <ScrollToTop />
        <Suspense>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<NoMatch />} />
              {/* <Route path="dashboard" element={<UserDashboard />} /> */}
              <Route path="admin" element={<Admin />} />
              <Route path="auth" element={<Signin />} />
              <Route path="auth/signup" element={<Signup />} />
              <Route path="stellplaetze/standorte" element={<PropertyList />} />
              {/* <Route path="stellplaetze" element={<PropertyDescription />} /> */}
              <Route path="stellplaetze/buchen" element={<PropertyBooking />} />
              <Route path="keypad" element={<UserKeypad />} />
              <Route path="impressum" element={<Impressum />} />
              <Route path="datenschutzerklaerung" element={<Datenschutzerklaerung />} />
              <Route path="agb" element={<AGB />} />
              {/* <Route path="tinyhof-welzow" element={<TinyhofWelzow />} /> */}
              <Route path="tinyhof-welzow/stellplaetze" element={<TinyhofWelzowStellplaetze />} />
              <Route path="tinyhof-welzow/anfahrt" element={<TinyhofWelzowAddress />} />
              <Route path="support" element={<Support />} />
              <Route path="status/canceled" element={<BookingCanceled />} />
              <Route path="status/success" element={<BookingSuccessful />} />
              <Route path="/s/welzow" element={<PropertyRedirect areaId="oJ8MTfafyRl41xaElogp" />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
{/*     </MatomoProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
