import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import LawElement from "../../components/LawElement";
import MetaValues from "../../components/MetaValues";

export default function AGB() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 5, }}>
      <MetaValues title="AGB" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", maxWidth: 800, rowGap: 3 }}>
        <Typography variant="h2" component="h1" textAlign="center" sx={{mb:3}}>AGB des Wohnmobil&shy;stellplatzes
          „Tinyhof Welzow“
        </Typography>
        <Typography>
          Die folgenden allgemeinen Geschäftsbedingungen sind Vertragsbestandteil.
          Mit dem Befahren des Stellplatzes nimmt der Stellplatzgast den Abschluss eines Stellplatzvertrages
          verbindlich an. Der Stellplatzvertrag kommt mit dem Eingang der Zahlung zustande. Die
          Stellplatzordnung ist Teil dieser AGB´s und erhält seine Wirkung bereits beim Einfahren auf den
          Wohnmobilstellplatz.
        </Typography>
        <LawElement title="1. Geltungsbereich:">
          Die nachfolgenden Vertragsbedingungen regeln die gegenseitigen Rechte und Pflichten vom
          Betreiber des Wohnmobilstellplatzes, Herr Henry Leukert und seinen Gästen.
        </LawElement>
        <LawElement title="2. Leistungsumfang">
          Für die vertraglichen Leistungen gelten ausschließlich die für den Reisezeitraum gültigen
          Drucksachen. Telefonische Absprachen, Nebenabreden und sonstige Vereinbarungen, gleich
          welcher Art, bedürfen zu ihrer Wirksamkeit der Schriftform und Bestätigung durch den
          Stellplatzbetreiber.
        </LawElement>
        <LawElement title="3. Preise">
          Alle Preise verstehen sich einschließlich der jeweils gültigen gesetzlichen Mehrwertsteuer. Es gilt
          die zum Buchungszeitpunkt gültige Preisliste des Betreibers. Sondervereinbarungen müssen
          schriftlich festgehalten werden.
          Wenn der Stellplatzgast die Stellplatzgebühr nicht oder nicht ausreichend bezahlt, sind eine
          Aufwandspauschale von 25 Euro/pro Tag und weitere anfallende Kosten zur Abfrage des amtlichen
          Kennzeichens beim Kraftfahrtbundesamt fällig.
        </LawElement>
        <LawElement title="4. Bezahlung">
          Die Bezahlung des Stellplatzes erfolgt bargeldlos über die Buchungsplattform der Homepage
          www.sl-homes.de
        </LawElement>
        <LawElement title="5. Rücktrittsrecht">
          Ein Rücktritt von der Buchung ist jederzeit vor Reisebeginn möglich. Der Rücktritt muss in
          schriftlicher Form erfolgen (per E-Mail, Fax oder Brief).
          Es gilt die folgende Stornierungsstaffel:
          Bis 3 Kalendertage vor Leistungsbeginn: kostenfrei
          Ab 2 Kalendertage vor Leistungsbeginn sowie bei Nichtanreise: 50 % des Mietpreises
        </LawElement>
        <LawElement title="6. Umbuchung">
          Wünscht der Gast eine Umbuchung, so prüft der Betreiber diesen Wunsch und versucht ihn nach
          Verfügbarkeit zu realisieren.
        </LawElement>
        <LawElement title="7. Verlängerung">
          Eine Verlängerung über die vertraglich vereinbarte Mietdauer hinaus ist möglich, sofern
          entsprechende freie Kapazitäten vorhanden sind. Für den Verlängerungszeitraum ist ein weiterer
          Parkschein zu lösen.
        </LawElement>
        <LawElement title="8. Vorzeitige Abreise">
          Bei vorzeitiger Abreise ist eine Rückvergütung des bereits gezahlten Entgeltes nicht möglich. Der
          Betreiber behält sich vor, den Platz neu zu vergeben.
        </LawElement>
        <LawElement title="9. An- und Abreise">
          Die Anreise ist täglich von 13 bis 22 Uhr möglich. Bitte beachten Sie die gesetzlichen Ruhezeiten
          und reduzieren Sie Störungen der Nachbarn auf ein nötiges. Am Abreisetag steht der Stellplatz dem
          Gast bis 11 Uhr zur Verfügung.
        </LawElement>
        <LawElement title="10. Stellplatzordnung">
          Die Gäste des Wohnmobilstellplatzes haben die Stellplatzordnung zu beachten. Hierzu gehört auch,
          dass der Platz sauber zu halten und die Ausstattung schonend zu behandeln ist. Beschädigungen,
          Defekte o.ä. sind dem Betreiber unverzüglich zu melden.
          Die Zufahrt zum Wohnmobilplatz ist nur den Benutzern des Stellplatzes gestattet.
          Der Wohnmobilplatz ist nur Wohnmobilisten vorbehalten. Für Caravan-Gespanne und Zelte sind
          keine Plätze vorgesehen. Das Aufstellen von Vorzelten ist gestattet.
          Unberechtigt abgestellte Fahrzeuge oder Zelte werden kostenpflichtig entfernt.
          Vermeiden Sie bitte jede Verunreinigung des Parkplatzes und seiner Umgebung. Der Müll ist in den
          hierfür vorgesehenen Behälter zu entsorgen.
          Die Versorgung (Frischwasser) und Entsorgung (Brauchwasser) erfolgt ausschließlich über die Verund Entsorgungsanlage. Das Entleeren der Chemie-Toiletten ist nur an der dafür vorgesehenen
          Stelle vorzunehmen. Anfallendes Abwasser aus dem Betrieb der Wohnmobile darf nur in den dafür
          vorgesehenen Ausguss der Entsorgungsstation entleert werden. Schmutzwasser darf nicht in die
          Umwelt gelangen. Das Waschen der Fahrzeuge ist auf dem Platz untersagt.
          Bitte beachten Sie, dass die Plätze um die Bäume herum gewisse Risiken durch herabfallendes
          Laub und Zweige bergen; hierfür übernehmen wir keine Haftung.
          Tierhalter haben dafür Sorge zu tragen, dass andere Stellplatzgäste nicht belästigt werden. Hunde
          jeder Größe müssen ständig angeleint gehalten werden. Das „Gassi -Führen“ ist nur außerhalb des
          Wohnmobilstellplatzes gestattet. Bitte Hundekot umgehend beseitigen.
          Grillen ist auf dem Stellplatz gestattet. Belästigungen der anderen Nutzer durch Feuer, Funkenflug
          und Qualm sind selbstverständlich zu vermeiden. Auf allen Stellplätzen besteht FeuerlöscherPflicht!
          Bitte nehmen Sie Rücksicht auf die anderen Gäste und Nachbarn und vermeiden Sie ruhestörenden
          Lärm. Insbesondere ist die Nachtruhe ab 22.00 Uhr bis 06.00 Uhr einzuhalten. Stellen Sie Radio,
          Fernsehgerät, usw. immer so leise, dass Sie andere nicht stören.
          Der Platz darf nur von haftpflichtversicherten und angemeldeten / zugelassenen Fahrzeugen genutzt
          werden. Benutzen und Befahren erfolgt auf eigene Gefahr. Die Höchstgeschwindigkeit beträgt 10
          km/h.
          Der Platz ist unbewacht. Jegliche Schadenersatzansprüche gegen die Betreiber sind ausgeschlossen.
          Mit dem Abstellen des Wohnmobils akzeptieren Sie unsere Platzordnung.
        </LawElement>
        <LawElement title="11. Hausrecht">
          Der Betreiber und seine Erfüllungsgehilfen sind in Ausübung des Hausrechts berechtigt, die
          Aufnahme von Personen zu verweigern oder sie des Platzes zu verweisen, wenn die Stellgebühr
          nicht rechtzeitig oder korrekt entrichtet wurde oder dieses zur Aufrechterhaltung von Ruhe und
          Ordnung auf dem Platz und im Interesse der übrigen Nutzer oder der Nachbarn erforderlich scheint.
        </LawElement>
        <LawElement title="12. Haftung">
        </LawElement>
        <LawElement title="12.1 Haftung des Stellplatzgastes" lower>
          Der Stellplatzgast haftet für durch ihn oder seine Mitreisenden verschuldete Schäden, insbesondere
          an den sich auf dem Platz befindlichen Anlagen oder der Ausstattung.
        </LawElement>
        <LawElement title="12.2 Haftungsbeschränkung es Betreibers" lower>
          Der Betreiber haftet auf Schadensersatz bei Vorsatz oder grober Fahrlässigkeit. Davon unabhängig
          haftet der Betreiber im Fall der Verletzung des Lebens, des Körpers oder der Gesundheit
          uneingeschränkt, soweit dies auf einer fahrlässigen Pflichtverletzung ihrerseits oder einer
          vorsätzlichen oder fahrlässigen Pflichtverletzung eines Erfüllungsgehilfen beruht. Der Betreiber
          haftet ebenfalls, wenn wesentliche Vertragspflichten (Kardinalpflichten) verletzt werden. Bei
          wesentlichen Vertragspflichten handelt es sich um solche Pflichten, deren Erfüllung eine
          ordnungsgemäße Durchführung des Vertrages erst ermöglichen und auf deren Erfüllung der Gast
          vertrauen darf. Bei der fahrlässigen Verletzung von Kardinalpflichten ist die Haftung auf den
          typischerweise vorhersehbaren Schaden und in jedem Fall auf den dreifachen Wert der angebotenen
          Leistung begrenzt. Der Betreiber haftet nicht für eine Lärmbelästigung durch Dritte. Für Schäden,
          die durch den Ausfall oder die Störung der Wasser- und Stromversorgung entstehen, wird ebenfalls
          keine Haftung übernommen. Darüber hinaus haftet der Betreiber nicht für den Verlust von
          Wertgegenständen, zum Beispiel durch Einbruch oder Diebstahl. Eine Haftung für Schäden am
          Wohnmobil wird ebenfalls ausgeschlossen. Diese Haftungsbeschränkung gilt auch für die
          Erfüllungsgehilfen des Betreibers
        </LawElement>
        <LawElement title="13. Datenverarbeitung und Datenschutz">
          Der Betreiber erhebt im Rahmen der Abwicklung von Verträgen Daten des Gastes im Rahmen der
          gesetzlichen Datenschutzvorgaben. Ausführliche Informationen zur Verarbeitung der Daten und den
          Rechten des Gastes in Zusammenhang mit diesem Thema sind in der Datenschutzerklärung
          abrufbar.
        </LawElement>
        <LawElement title="13.1 Dauer der Speicherung" lower>
          Personenbezogene Daten werden nur so lange gespeichert, bis der Zweck erfüllt ist, und soweit sie
          nicht handels- und steuerrechtlichen Aufbewahrungsfristen unterliegen, max. 3 Jahre.
        </LawElement>
        <LawElement title="13.2 Auskunftsrecht des Gastes" lower>
          Dem Gast steht im Rahmen von gesetzlichen Bestimmungen das Recht zu, Auskunft über die ihn
          betreffenden gespeicherten personenbezogenen Daten zu erhalten oder diese einzusehen. Anfragen
          zum Thema Datenschutz können an den Datenschutzbeauftragten den Betreiber gerichtet werden.
        </LawElement>
        <LawElement title="14 Schlussbestimmungen">
        </LawElement>
        <LawElement title="14.1 Anwendbares Recht" lower>
          Auf Verträge zwischen dem Betreiber und dem Gast findet das Recht der Bundesrepublik
          Deutschland Anwendung.
        </LawElement>
        <LawElement title="14.2 Gerichtsstand" lower>
          Sofern es sich beim Gast um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder
          um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus
          Vertragsverhältnissen zwischen dem Gast und dem Betreiber der eingetragene Wohnsitz des
          Betreibers. Vertragssprache ist ausschließlich deutsch.
        </LawElement>
        <LawElement title="14.3 Salvatorische Klausel" lower>
          Soweit einzelne Bestimmungen dieser AGB unwirksam sind oder werden, wird die Gültigkeit der
          übrigen Bestimmungen dadurch nicht berührt.
        </LawElement>
        <Typography>
          Stand: Juni 2022
        </Typography>

        <Button variant="contained" color="secondary" size="large" onClick={() => { navigate("/"); }} sx={{ mt: 5, alignSelf: "center" }}>Zur Startseite</Button>
      </Box >
    </Box >
  );
}