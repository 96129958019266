import { Box, Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import LawElement from "../../components/LawElement";
import MetaValues from "../../components/MetaValues";

export default function Datenschutzerklaerung() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 5, }}>
      <MetaValues title="Datenschutzerklärung" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", maxWidth: 800, rowGap: 3 }}>
        <Typography variant="h2" component="h1" textAlign="center" sx={{ mb: 3 }}>Datenschutz&shy;erklärung</Typography>

        <LawElement title="1. Datenschutz auf einen Blick">
          <LawElement title="Allgemeine Hinweise" lower>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
            Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
          </LawElement>
        </LawElement>

        <LawElement title="Datenerfassung auf dieser Website">
          <LawElement title="Wer ist verantwortlich für die Datenerfassung auf dieser Website?" lower>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
            können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
          </LawElement>
          <LawElement title="Wie erfassen wir Ihre Daten?" lower>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
            Daten handeln, die Sie in ein Kontaktformular eingeben.<br /><br />
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
          </LawElement>
          <LawElement title="Wofür nutzen wir Ihre Daten?" lower>
            Hauptsächlich nutzen wir Ihre Daten, um abgeschlossene Buchungen zu verarbeiten und zu speichern. Ein Teil der Daten wird außerdem erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </LawElement>
          <LawElement title="Welche Rechte haben Sie bezüglich Ihrer Daten?" lower>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
            bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.<br /><br />
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden
          </LawElement>
        </LawElement>

        <LawElement title="2. Hosting">
          <LawElement title="Externes Hosting" lower>
            Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die
            auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
            a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
            Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.<br /><br />
            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
            bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
            Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
            Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
            von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
            Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.<br /><br />
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
            erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.<br /><br />
            Wir setzen folgenden Hoster ein:<br /><br />
            Google Ireland Limited<br />
            Gordon House, Barrow Street<br />
            Dublin 4<br />
            Irland<br />
          </LawElement>
        </LawElement>

        <LawElement title="3. Allgemeine Hinweise und Pflichtinformationen">
          <LawElement title="Datenschutz" lower>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
            dieser Datenschutzerklärung.<br /><br />
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
            und zu welchem Zweck das geschieht.<br /><br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich
          </LawElement>
          <LawElement title="Hinweis zur verantwortlichen Stelle" lower>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br /><br />
            Henry Leukert<br />
            Tübinger Straße 32<br />
            72666 Neckartailfingen<br /><br />
            Telefon: +49 (0) 7127 528177<br />
            E-Mail: support@sl-homes.de<br /><br />
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
            die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.

          </LawElement>
          <LawElement title="Speicherdauer" lower>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
            Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
            berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
            werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
            personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
            letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
          </LawElement>
          <LawElement title="Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website" lower>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
            Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
            nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
            49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
            Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
            auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
            Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
            Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
            zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
            Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
          </LawElement>
          <LawElement title="Widerruf Ihrer Einwilligung zur Datenverarbeitung" lower>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.

          </LawElement>
          <LawElement title="Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)" lower>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
            SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
            WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
            WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
            SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
            VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
            RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br /><br />
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
            SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
            BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
            EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
            VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
            ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
            NACH ART. 21 ABS. 2 DSGVO).
          </LawElement>
          <LawElement title="Beschwerderecht bei der zuständigen Aufsichtsbehörde" lower>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </LawElement>
          <LawElement title="Recht auf Datenübertragbarkeit" lower>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </LawElement>
          <LawElement title="Auskunft, Löschung und Berichtigung" lower>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
            Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
            Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
            zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
          </LawElement>
          <LawElement title="Recht auf Einschränkung der Verarbeitung" lower>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
            folgenden Fällen:
            <ul>
              <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
              <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
              <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
              <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                zu verlangen.</li>
            </ul>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
            juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
            eines Mitgliedstaats verarbeitet werden.
          </LawElement>
          <LawElement title="SSL- bzw. TLS-Verschlüsselung" lower>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
            „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br /><br />
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
            von Dritten mitgelesen werden.
          </LawElement>
        </LawElement>

        <LawElement title="4. Datenerfassung auf dieser Website">
          <LawElement title="Server-Log-Dateien" lower>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-LogDateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br /><br />
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
            hierzu müssen die Server-Log-Files erfasst werden.
          </LawElement>
          <LawElement title="Matomo (selbst gehostet)" lower>
            Diese Webseite verwendet Matomo, eine Open Source, selbstgehostete Software um anonyme Nutzungsdaten für diese Webseite zu sammeln.<br /><br />

            Die Daten zum Verhalten der Besucher werden gesammelt um eventuelle Probleme wie nicht gefundene Seiten, Suchmaschinenprobleme oder unbeliebte Seiten herauszufinden. Sobald die Daten (Anzahl der Besucher die Fehlerseiten oder nur eine Seite sehen, usw.) verarbeitet werden, erzeugt Matomo Berichte für die Webseitenbetreiber, damit diese darauf reagieren können. (Layoutveränderungen, neue Inhalte, usw.)<br /><br />

            Matomo verarbeitet die folgenden Daten:

            <ul>
              <li>Cookies</li>
              <li>Anonymisierte IP-Adressen indem die letzten 2 bytes entfernt werden (also 198.51.0.0 anstatt 198.51.100.54)</li>
              <li>Pseudoanonymisierter Standort (basierend auf der anonymisierten IP-Adresse)</li>
              <li>Datum und Uhrzeit</li>
              <li>Titel der aufgerufenen Seite</li>
              <li>URL der aufgerufenen Seite</li>
              <li>URL der vorhergehenden Seite (sofern diese das erlaubt)</li>
              <li>Bildschirmauflösung</li>
              <li>Lokale Zeit</li>
              <li>Dateien die angeklickt und heruntergeladen wurden</li>
              <li>Externe Links</li>
              <li>Dauer des Seitenaufbaus</li>
              <li>Land, Region, Stadt (mit niedriger Genauigkeit aufgrund von IP-Adresse)</li>
              <li>Hauptsprache des Browsers</li>
              <li>User Agent des Browsers</li>
              <li>Interaktionen mit Formularen (aber nicht deren Inhalt)</li>
            </ul>

            Da Matomo Daten basierend auf legitimen Interesse sammelt, können Sie folgende Rechte ausüben:

            <ul>
              <li>Recht auf Auskunft und Datenübertragbarkeit: Sie können jederzeit alle Ihre Daten anfordern.</li>
              <li>Recht auf Löschung und Berichtigung: Sie können jederzeit anfragen, dass wir alle Ihre Daten vollständig löschen.</li>
              <li>Recht auf Widerspruch und Einschränkung der Verarbeitung: Sie können jederzeit der Datensammlung widersprechen, indem Sie diese Box abhaken:</li>
            </ul>
            {/* //FIXME */}
            Aktuell ist Matomo allgemein deaktiviert.
          </LawElement>
          {/* <iframe
            title="OptOut Frame"
            style={{ border: 0, height: "250px", width: "100%" }}
            src="https://analytics.sl-homes.de/index.php?module=CoreAdminHome&action=optOut&language=de&fontColor=ffffff&fontFamily=sans-serif"
          ></iframe> */}

          <LawElement title="Google Cloud und Google Firebase" lower>
            Um Ihre Auftrags- und Buchungsdaten verarbeiten zu können, arbeiten wir mit Google Cloud Diensten zusammen. Zu den genutzten Diensten zählen folgende:
            <ul>
              <li>Cloud Functions for Firebase</li>
              <ul>
                <li>Übertragene Daten: IP-Adressen</li>
                <li>Dauer der Speicherung: Temporär, nur zur Ausführung Verarbeitung einer Anfrage</li>
                <li>Datenschutzerklärung: <Link href="https://cloud.google.com/terms/">Google Cloud Platform Terms of Service</Link></li>
              </ul>
              <li>Firebase Hosting</li>
              <ul>
                <li>Übertragene Daten: IP-Adressen</li>
                <li>Dauer der Speicherung: Einige Monate, um Missbrauch zu verhindern</li>
                <li>Datenschutzerklärungen: <Link href="https://firebase.google.com/terms/billing">Firebase Paid Services Agreement</Link>, <Link href="https://developers.google.com/terms/">Google APIs Terms of Service</Link>, <Link href="https://firebase.google.com/terms/data-processing-terms">Firebase Data Processing and Security Terms</Link></li>
              </ul>
              <li>Firebase Firestore</li>
              <ul>
                <li>Übertragene Daten: IP-Adressen</li>
                <li>Dauer der Speicherung: Temporär, nur zur Ausführung Verarbeitung einer Anfrage<br />⠀</li>

                <li>Übertragene Daten: Buchungsdaten, Name, Telefonnummer, Kraftfahrzeug-Kennzeichen, Name des Karteninhabers
                  E-Mail-Adresse,
                  Kundennummer,
                  Bestellnummer,
                  Bankverbindung,
                  Kreditkartendaten,
                  Gültigkeitsdauer der Kreditkarte,
                  Prüfnummer der Kreditkarte (CVC),
                  Datum und Uhrzeit der Transaktion,
                  Transaktionssumme,
                  Name des Anbieters,
                  Ort </li>
                <li>Dauer der Speicherung: Drei Jahre nach dem Ende der letzten Buchung. Wir müssen diese Daten aus rechtlichen Gründen aufbewaren.<br />⠀</li>
                <li>Datenschutzerklärung: <Link href="https://cloud.google.com/terms/">Google Cloud Platform Terms of Service</Link></li>
              </ul>
            </ul>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br /><br />
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der Verarbeitung von Buchungsdaten zur Bereitstellung einer Nutzungsgenehmigung.<br /><br />
            Adresse Google Ireland Limited:<br />
            Google Ireland Limited<br />
            Gordon House, Barrow Street<br />
            Dublin 4<br />
            Irland<br />
          </LawElement>
          <LawElement title="PayPal" lower>
            Wir bieten die Möglichkeit, den Zahlungsvorgang über den Zahlungsdienstleister PayPal (PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg) abzuwickeln. Dies entspricht unserem berechtigten Interesse, eine effiziente und sichere Zahlungsmethode anzubieten (Art. 6 Abs. 1 lit. f DSGVO). In dem Zusammenhang geben wir folgende Daten an PayPal weiter, soweit es für die Vertragserfüllung erforderlich ist (Art. 6 Abs. 1 lit b. DSGVO).

            <ul>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>Adresse</li>
              <li>E-Mail-Adresse</li>
              <li>Telefonnummer</li>
            </ul>

            Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die Übermittlung Ihrer personenbezogenen Daten können wir eine Zahlung über PayPal nicht durchführen.<br /><br />

            PayPal führt bei verschiedenen Diensten wie Zahlung per Lastschrift eine Bonitätsauskunft durch, um Ihre Zahlungsbereitschaft und -fähigkeit sicherzustellen. Dies entspricht dem berechtigten Interesse PayPals (gem. Art. 6 Abs. 1 lit. f DSGVO) und dient der Vertragsdurchführung (gem. Art. 6 Abs. 1 lit. b DSGVO). Dafür werden Ihre Daten (Name, Adresse und Geburtsdatum, Bankkontodetails) an Auskunfteien weitergegeben. Wir haben auf diesen Prozess keinen Einfluss und erhalten lediglich das Ergebnis, ob die Zahlung durchgeführt oder abgelehnt wurde oder eine Überprüfung aussteht.<br /><br />

            Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten gegenüber PayPal finden Sie <Link href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">hier</Link><br /><br />

            Ihre Daten werden bis zum Abschluss der Zahlungsabwicklung gespeichert. Dazu zählt auch der Zeitraum der für die Bearbeitung von Rückerstattungen, Forderungsmanagement und Betrugsprävention erforderlich ist.
          </LawElement>
          <LawElement title="Anfrage per E-Mail, Telefon oder Telefax" lower>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
            bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br /><br />
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
            widerrufbar.<br /><br />
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
            (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </LawElement>
        </LawElement>

        <LawElement title="5. Plugins und Tools">
          <LawElement title="OpenStreetMap" lower>
            Wir nutzen den Kartendienst von OpenStreetMap (OSM).<br /><br />
            Wir binden das Kartenmaterial von OpenStreetMap auf dem Server der OpenStreetMap Foundation, St
            John’s Innovation Centre, Cowley Road, Cambridge, CB4 0WS, Großbritannien, ein. Großbritannien gilt als
            datenschutzrechtlich sicherer Drittstaat. Das bedeutet, dass Großbritannien ein Datenschutzniveau
            aufweist, das dem Datenschutzniveau in der Europäischen Union entspricht. Bei der Nutzung der
            OpenStreetMap-Karten wird eine Verbindung zu den Servern der OpenStreetMap-Foundation hergestellt.
            Dabei können u. a. Ihre IP-Adresse und weitere Informationen über Ihr Verhalten auf dieser Website an die
            OSMF weitergeleitet werden. OpenStreetMap speichert hierzu unter Umständen Cookies in Ihrem Browser
            oder setzt vergleichbare Wiedererkennungstechnologien ein.<br /><br />
            Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden Darstellung unserer OnlineAngebote und einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein
            berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
            abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und §
            25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen
            im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
            jederzeit widerrufbar.
          </LawElement>
        </LawElement>

        <LawElement title="Basis des Legitimen Interesses">
          Die Datenverarbeitung basiert auf dem Prinzip des legitimen Interesses.<br /><br />

          Verarbeiten der Daten hilft uns herauszufinden, was auf unserer Seite funktioniert und was nicht. Zum Beispiel finden wir damit heraus, ob die Inhalte gut ankommen oder wie wir die Struktur der Webseite verbessern können. Unser Team profitiert davon und kann darauf reagieren. Aufgrund der Datenverarbeitung profitieren Sie somit von einer Webseite, die laufend besser wird.<br /><br />

          Ohne den Daten könnten wir den Service nicht bieten. Ihre Daten werden ausschließlich zum Verbessern der Webseitennutzung verwendet.
        </LawElement>

        <Typography>
          Quelle: <Link href="https://www.e-recht24.de" target="_blank" rel="noopener">https://www.e-recht24.de</Link> mit Modifikationen <br />
          Stand: 03. September 2022
        </Typography>

        <Button variant="contained" color="secondary" size="large" onClick={() => { navigate("/"); }} sx={{ mt: 5, alignSelf: "center" }}>Zur Startseite</Button>
      </Box >
    </Box >
  );
}