import { IconButton, Toolbar, Typography, Link, Grid, AppBar } from "@mui/material";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MenuIcon from "@mui/icons-material/Menu"
import { useNavigate } from "react-router-dom";
import AppDrawer from "./AppDrawer";
import React from 'react';

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    color: trigger ? "paper" : "transparent",
  });
}

export default function TopBar(props) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  function toggleDrawer() {
    setDrawerOpen(prev => !prev);
  }

  return (
    <header>
      <AppDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} isAdmin={props.isAdmin} />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="Navigationsmenü"
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" textAlign="center" sx={{ userSelect: "none" }}>
                  <Link variant="inherit" color="inherit" component="div" underline="none" textAlign="center" href="/" onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }} style={{ cursor: "pointer" }}>
                    {props.pageTitle}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography textAlign="end">
                  Tel: +49 170 7970885
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </header >
  );
}

