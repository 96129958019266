/* import { useMatomo } from "@jonkoops/matomo-tracker-react"; */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  /* const { trackPageView } = useMatomo(); */
  
  useEffect(() => {
    /* trackPageView(); */
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname, /* trackPageView */]);

  return children;
}