import { Grid, Stack, Typography } from "@mui/material";

export default function BookingElement(props) {
  return (
    <Grid item xs={12} lg={6}>
      <Stack spacing={1.5}>
        <Typography variant="subtitle1">{props.title}</Typography>
        {props.children}
      </Stack>
    </Grid>
  )
}