import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PropertyRedirect(props) {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/stellplaetze/buchen", { state: { areaId: props.areaId } });
  })

  return (
    <Typography>Sie werden weitergeleitet, bitte warten</Typography>
  );
}