import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export default function AppDrawerListItem(props) {
  const ps = props.external ? {
    target: "_blank",
    href: props.target,
    rel: "noopener",
  } : {
    onClick: () => props.handleClick(props.target),
  };

  return (
    <ListItemButton
      key={props.text}
      selected={props.location.pathname === props.target}
      {...ps}>
      <ListItemIcon sx={{ color: "white" }}>
        {props.icon}
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItemButton >
  );
}