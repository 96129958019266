import { Button, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import LoginAlert from "../../components/LoginAlert";
import MetaValues from "../../components/MetaValues";

export default function Signin() {
  const [formData, setFormData] = React.useState({ email: "", password: "" });
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");
  const auth = getAuth();
  const navigate = useNavigate();

  function handleChange(event) {
    const { id, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [id]: value,
    }))
  }

  function handleSubmit() {
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then(userCredentials => {
        //Signed in
        navigate("/", { replace: true });
      })
      .catch(error => {
        //Error
        console.log(error.code + " | " + error.message);
        switch (error.code) {
          case "auth/invalid-email":
            setAlertText("Dieser Nutzer wurde nicht gefunden");
            break;
          case "auth/user-not-found":
            setAlertText("Dieser Nutzer wurde nicht gefunden");
            break;
          case "auth/wrong-password":
            setAlertText("Das Passwort ist falsch");
            break;
          case "auth/user-disabled":
            setAlertText("Dieser Nutzer wurde deaktiviert. Melden Sie sich bei Fragen an den Support.");
            break;
          default:
            break;
        }
        setAlertOpen(true);
      });
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", flexGrow: 1 }}>
      <MetaValues title="Login"/>
      <Paper variant="outlined" sx={{ padding: 5, minWidth: 350, m: 2}} >
        <Typography variant="h4" component="h1" mb={3} textAlign="center">Login</Typography>

        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField id="email" label="Email Adresse" type="email" autoComplete="username" value={formData.email} margin="dense" onChange={handleChange}></TextField>
            <TextField id="password" label="Passwort" type="password" autoComplete="current-password" value={formData.password} margin="dense" onChange={handleChange} ></TextField>
            <Button color="secondary" variant="contained" type="submit" sx={{ mt: 2, mb: 2 }}>Anmelden</Button>
            <LoginAlert open={alertOpen} text={alertText} />
            <Button disabled variant="text" component={RouterLink} to="/auth/signup" sx={{ mt: 2 }}>Neues Konto erstellen</Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}