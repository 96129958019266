import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NiceLink(props) {
  const navigate = useNavigate();

  return (
    <Link href={props.href} underline={props.noUnderline ? "hover" : "always"} onClick={(e) => {
      e.preventDefault();
      if (!props.external) {
        if (!props.action) {
          navigate(props.href);
        } else {
          props.action();
        }
      } else {
        window.open(props.href, '_blank', 'noopener');
      }
    }}>{props.children}</Link>
  );
}